// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyACYRwwsUge6OD4ePBcjBWkXw1Owkwb4g0',
    authDomain: 'markabecker-prod.firebaseapp.com',
    databaseURL: 'https://markabecker-prod.firebaseio.com',
    projectId: 'markabecker-prod',
    storageBucket: 'markabecker-prod.appspot.com',
    messagingSenderId: '414779529509',
    appId: '1:414779529509:web:a3c7404867a6ea48d2e2cc',
    measurementId: 'G-KNLSPLJV84'
  },
  flickr: {
    key: '4429d8594c7a35119789123d6df7551b',
    secret: 'd3df6727b3abb687',
    userId: '194444544@N03',
    userId2: '194444544%40N03'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
