import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ContentHeaderService } from '../services/content-header.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss'],
})
export class ContentHeaderComponent implements OnInit {
  // isLoggedIn = false;
  pageNameCurrent$ = this.contentHeaderService.getPageNameCurrent();
  pageTitleExtra$ = this.contentHeaderService.getPageTitleExtra();
  // userLoggedInDisplayName = '';
  // userLoggedInPhotoURL = '';
  // userLoggedIn = '';

  currentUser$ = this.authService.user$;

  constructor(
    private router: Router,
    private contentHeaderService: ContentHeaderService,
    private authService: AuthService
  ) {}

  ngOnInit() {}

  goToUser() {
    this.router.navigate(['/user']);
  }

  signOutAndComeBack() {
    this.authService.redirectUrl = this.router.url;
    this.authService.signOut();
  }

  signInAndComeBack() {
    if (this.authService.redirectUrl == '/user/signin') {
      this.authService.redirectUrl = '/';
    } else {
      this.authService.redirectUrl = this.router.url;
    }
    this.router.navigate(['/user/signin']);
  }
}
