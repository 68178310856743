import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable, EMPTY, of } from 'rxjs';
import { User } from '../models/user';

@Component({
  selector: 'app-page-frame',
  templateUrl: './page-frame.component.html',
  styleUrls: ['./page-frame.component.scss'],
})
export class PageFrameComponent implements OnInit {
  pageNameGoto = '';
  menuOpened = false;
  titleExtra = 'filler';
  user$: Observable<User>;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {}

  menuButtonHover(pageName: string) {
    this.pageNameGoto = '' + pageName;
  }
  menuButtonOut() {
    this.pageNameGoto = '';
  }

  menuButtonClick(pageName: string) {
    if (pageName === 'signout') {
      this.authService.signOut();
      this.router.navigate(['/user/signin']);
    } else {
      this.menuOpened = false;
      this.pageNameGoto = '';
      this.router.navigate([pageName]);
    }
  }

  showMenuButtonClick() {
    this.menuOpened = !this.menuOpened;
    if (this.menuOpened) {
      const elem = document.getElementById('menu-icon-close-button');
      setTimeout(() => elem.focus(), 10);
    }
  }

  async menuIconOpenOnBlur(e) {
    await new Promise((resolve) => setTimeout(resolve, 200));
    this.menuOpened = false;
  }
}
