import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

/** Error when invalid control is dirty, touched, or submitted. */
export class FirestoreError extends Error {
  fileName: string;
  methodName: string;
  args: object;
  timestamp: Date;
  constructor(
    message: string,
    fileName: string,
    methodName: string,
    args: object,
    timestamp: Date
  ) {
    super(message);
    this.name = 'FirestoreError';
    this.fileName = fileName;
    this.methodName = methodName;
    this.args = args;
    this.timestamp = timestamp;
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class CustomError extends Error {
  values: object;
  constructor(message: string, values: object) {
    super(message);
    this.name = 'CustomError';
    this.values = values;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService implements ErrorHandler {
  constructor(private injector: Injector, private ngZone: NgZone) {}

  handleError(error: any) {
    const router = this.injector.get(Router);
    if (Error instanceof HttpErrorResponse) {
      console.log(error.status);
      //
    } else if (error instanceof ErrorEvent) {
      console.error('▼ ▼ ▼ error.service --> ErrorEvent: ▼ ▼ ▼');
      console.error('type: ', error.type);
      console.error('message: ', error.message);
      console.error('error: ', error);
      console.error('▲ ▲ ▲ error.service --> ErrorEvent: ▲ ▲ ▲');
      //
    } else if (error instanceof FirestoreError && error instanceof Error) {
      console.error('▼ ▼ ▼ error.service --> FirestoreError: ▼ ▼ ▼');
      console.error('name: ', error.name);
      console.error('message: ', error.message);
      console.error('fileName: ', error.fileName);
      console.error('methodName: ', error.methodName);
      console.error('variableName: ', error.args);
      console.error('timestamp: ', error.timestamp);
      console.error('▲ ▲ ▲ error.service --> FirestoreError: ▲ ▲ ▲');
      //
    } else if (error instanceof CustomError && error instanceof Error) {
      console.error('▼ ▼ ▼ error.service --> CustomError: ▼ ▼ ▼');
      console.error('name: ', error.name);
      console.error('message: ', error.message);
      console.error('values: ', error.values);
      console.error('▲ ▲ ▲ error.service --> CustomError: ▲ ▲ ▲');
      // this.loopObjectsToFlatArray(error.values).forEach(i => {
      //   console.log(i);
      // });
      // this.flattenObject(error.values);
      // // console.log('values: ', this.loopObjectsToFlatArray(error.values));
      //
    } else if (error instanceof Error) {
      console.error('▼ ▼ ▼ error.service --> Error: ▼ ▼ ▼');
      console.error('name: ', error.name);
      console.error('message: ', error.message);
      console.error('stack: ', error.stack);
      console.error('▲ ▲ ▲ error.service --> Error: ▲ ▲ ▲');
      //
    } else {
      console.error('▼ ▼ ▼ error.service --> error.obj: ▼ ▼ ▼');
      console.error('name: ', error.name);
      console.error('message: ', error.message);
      console.error('type: ', error.type);
      console.error('▲ ▲ ▲ error.service --> error.obj: ▲ ▲ ▲');
    }
    // this.ngZone.run( () => router.navigate(['error']));
  }

  private loopObjectsToFlatArray(obj: object): string[] {
    const retArr = [];
    for (const prop in obj) {
      if (prop) {
        if (typeof obj[prop] === 'string') {
          retArr.push(`${prop}: ${obj[prop]}`);
        } else if (typeof obj[prop] === 'object') {
          retArr.push(this.loopObjectsToFlatArray(obj[prop]));
        } else {
          retArr.push(`${prop}: ${obj[prop]}`);
        }
      }
    }
    return [].concat(...retArr);
  }

  private flattenObject(o: object): void {
    // #Source https://bit.ly/2neWfJ2
    const flattenObject = (obj, prefix = '') =>
      Object.keys(obj).reduce((acc, k) => {
        const pre = prefix.length ? prefix + '.' : '';
        if (typeof obj[k] === 'object') {
          Object.assign(acc, flattenObject(obj[k], pre + k));
        } else {
          acc[pre + k] = obj[k];
        }
        return acc;
      }, {});
    console.log(flattenObject(o));
  }

  public logErrorObject(err: any, values: object, timestamp: string) {
    console.log('err: ', err);
    console.log('values: ', values);
    console.log('timestamp: ', timestamp);
  }

  public logErrorString(message: string) {
    console.log('message: ', message);
  }
}
