import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { switchMap, tap, map, catchError } from 'rxjs/operators';

import { User } from '../models/user';
import { MessageService } from './message.service';
import { Observable, Subject, BehaviorSubject, of, throwError } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FirebaseUser } from '../models/firebase-user';
import { FirebaseUserMetadata } from '../models/firebase-user-metadata';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  fakeData: User = {
    displayName: '',
    email: '',
    emailVerified: false,
    firstName: '',
    lastName: '',
    photoURL: '',
    providerId: '',
    quote: '',
    uid: '',
    userRole: 'user',
  };

  redirectUrl: string;

  userToPass: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  userToPass$ = this.userToPass.asObservable();

  uid: BehaviorSubject<string> = new BehaviorSubject<string>('');
  uid$ = this.uid.asObservable();

  userRole: BehaviorSubject<string> = new BehaviorSubject<string>('');
  userRole$ = this.userRole.asObservable();

  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedIn.asObservable();

  // Look into storing this and not calling each page load
  user$: Observable<User> = this.angularFireAuth.authState.pipe(
    switchMap((user) => {
      // Logged in
      if (user) {
        // this.userToPass.next(user);
        console.log('called auth.service:user$');
        return this.angularFirestore
          .doc<User>(`users/${user.uid}`)
          .valueChanges()
          .pipe(
            map((user: User) => {
              this.userRole.next(user.userRole);
              this.uid.next(user.uid);
              this.userToPass.next(user);
              this.isLoggedIn.next(true);
              return user;
            })
          );
      } else {
        // Logged out
        this.userRole.next('');
        this.uid.next('');
        this.isLoggedIn.next(false);
        this.userToPass.next(null);
        return of(null);
      }
    })
  );

  constructor(
    private messageService: MessageService,
    private angularFirestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private angularFireFunctions: AngularFireFunctions,
    private router: Router
  ) {}

  async signOut() {
    this.angularFireAuth.onAuthStateChanged((user) => {
      if (!!user) {
        this.messageService.addMessage(
          `User: ${this.angularFireAuth.currentUser} logged OUT`
        );
      }
    });
    await this.angularFireAuth.signOut();

    if (this.redirectUrl && this.redirectUrl != '') {
      if (this.redirectUrl == '/user') {
        this.router.navigate(['/user/signin']);
      } else {
        this.router.navigateByUrl(this.redirectUrl);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  async appleSignin() {}

  async facebookSignin() {}

  async githubSignin() {}

  async googleSignin(): Promise<any> {
    const provider = new firebase.auth.GoogleAuthProvider();
    // const provider = new firebase.auth.GoogleAuthProvider();
    let firebaseUserToReturn: FirebaseUser;
    let user: User;
    let isNew = true;
    await this.angularFireAuth //.auth
      .signInWithPopup(provider)
      .then((userCred) => {
        firebaseUserToReturn = userCred.user;
        if (!userCred.additionalUserInfo.isNewUser) {
          isNew = false;
          // Fix up lastLoginTime
          this.callJustSignedInUser(userCred.user.uid, userCred.user.metadata)
            .then((result) => {
              // console.log('resolver callJustSignedInUser: ', result);
              return result;
            })
            .catch((error) => {
              // console.log('reject callJustSignedInUser: ', error);
              return error;
            });
        } else {
          const dName = userCred.user.displayName;
          const metadataObj = userCred.user.metadata as FirebaseUserMetadata;
          user = {
            displayName: dName,
            email: userCred.user.email,
            emailVerified: userCred.user.emailVerified,
            firstName: dName.substring(0, dName.indexOf(' ')),
            isAnonymous: userCred.user.isAnonymous,
            lastName: dName.substring(dName.indexOf(' ') + 1),
            metadata: {
              createdAt: metadataObj.createdAt,
              creationTime: userCred.user.metadata.creationTime,
              lastLoginAt: metadataObj.lastLoginAt,
              lastSignInTime: userCred.user.metadata.lastSignInTime,
            },
            phoneNumber: userCred.user.phoneNumber,
            photoURL: userCred.user.photoURL,
            providerData: userCred.user.providerData,
            providerId: userCred.user.providerId,
            quote: '',
            refreshToken: userCred.user.refreshToken,
            tenantId: userCred.user.tenantId,
            uid: userCred.user.uid,
            userRole: 'user',
          };
        }
      })
      .catch((error) => {
        console.log('Error signInWithPopup:', error);
        return error;
      });

    // Need to create user to pass. Has not been filled completely yet
    if (isNew) {
      await this.callJustSignedUpUser(user)
        .then((result) => {
          console.log('resolver callJustSignedUpUser: ', result);
          return result;
        })
        .catch((error) => {
          console.log('reject callJustSignedUpUser: ', error);
          return error;
        });
    } else {
      return firebaseUserToReturn;
    }
  }

  async hotmailSignin() {}

  async twitterSignin() {}

  async yahooSignin() {}

  async phoneSignin() {}

  async anonymousSignin() {}

  async signUpEmailPasswordUser(user: User, password: string): Promise<any> {
    // user passed from caller should be filled out completely
    await this.angularFireAuth //.auth
      .createUserWithEmailAndPassword(user.email, password)
      .then((userCred) => {
        // Update user auth profile
        const dName = user.firstName + ' ' + user.lastName;
        userCred.user.updateProfile({
          displayName: dName,
        });
        const metadataObj = userCred.user.metadata as FirebaseUserMetadata;
        // user.currentLoginTime = Date.now().toString();
        user.displayName = dName;
        user.email = userCred.user.email;
        user.emailVerified = userCred.user.emailVerified;
        user.firstName = user.firstName;
        user.isAnonymous = userCred.user.isAnonymous;
        user.lastName = user.lastName;
        user.metadata.createdAt = metadataObj.createdAt;
        user.metadata.creationTime = userCred.user.metadata.creationTime;
        user.metadata.lastLoginAt = metadataObj.lastLoginAt;
        user.metadata.lastSignInTime = userCred.user.metadata.lastSignInTime;
        user.phoneNumber = userCred.user.phoneNumber;
        user.photoURL = userCred.user.photoURL;
        user.providerData = userCred.user.providerData;
        user.providerId = userCred.user.providerId;
        user.quote = '';
        user.refreshToken = userCred.user.refreshToken;
        user.tenantId = userCred.user.tenantId;
        user.uid = userCred.user.uid;
        user.userRole = 'user';
      })
      .catch((error) => {
        console.log('Error createUserWithEmailAndPassword:', error);
        return error;
      });
    return await this.callJustSignedUpUser(user)
      .then((result) => {
        console.log('resolver callJustSignedUpUser: ', result);
        return result;
      })
      .catch((error) => {
        console.log('reject callJustSignedUpUser: ', error);
        return error;
      });
  }

  signInEmailPasswordUser(email: string, password: string): Promise<string> {
    return new Promise((resolver, reject) => {
      // Add user to firebase-auth
      this.angularFireAuth //.auth
        .signInWithEmailAndPassword(email, password)
        .then((userCred) => {
          this.messageService.addMessage(
            `User: ${userCred.user.email} logged IN`
          );
          // Fix up lastSignInTime
          this.callJustSignedInUser(userCred.user.uid, userCred.user.metadata)
            .then((result) => {
              // console.log('resolver callJustSignedInUser: ', result);
              return result;
            })
            .catch((error) => {
              console.log('reject callJustSignedInUser: ', error);
              return error;
            });
          resolver('resolver: ' + userCred.user.email);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  deleteUser() {}

  loadDefaultUserInfo() {
    return of<User>(this.fakeData).pipe(delay(10));
  }

  private callJustSignedUpUser(user: User): Promise<any> {
    return new Promise((resolver, reject) => {
      const justSignedUpUser =
        this.angularFireFunctions.httpsCallable('justSignedUpUser');
      justSignedUpUser({ user })
        .toPromise()
        .then((result) => {
          console.log('resolver justSignedUpUser: ', result);
          resolver('resolver justSignedUpUser: ');
        })
        .catch((error) => {
          console.log('reject justSignedUpUser: ', error);
          return reject(error);
        });
    });
  }

  private callJustSignedInUser(
    uid: string,
    metadata: FirebaseUserMetadata
  ): Promise<any> {
    return new Promise((resolver, reject) => {
      const justSignedInUser =
        this.angularFireFunctions.httpsCallable('justSignedInUser');
      justSignedInUser({ uid, metadata })
        .toPromise()
        .then((result) => {
          // console.log('resolver justSignedInUser: ', result);
          resolver('resolver justSignedInUser: ');
        })
        .catch((error) => {
          console.log('reject justSignedInUser: ', error);
          return reject(error);
        });
    });
  }

  changeRoleOfUser(email: string, role: string): Promise<string> {
    return new Promise((resolver, reject) => {
      const changeRoleOfUser =
        this.angularFireFunctions.httpsCallable('changeRoleOfUser');
      changeRoleOfUser({ email, role })
        .toPromise()
        .then((result) => {
          console.log('resolver: changeRoleOfUser: ', result);
          resolver('string');
        })
        .catch((error) => {
          console.log('reject: changeRoleOfUser: ', error);
          return reject(error);
        });
    });
  }

  // The list of ProviderID types:
  // EmailAuthProviderID: password
  // PhoneAuthProviderID: phone
  // GoogleAuthProviderID: google.com
  // FacebookAuthProviderID: facebook.com
  // TwitterAuthProviderID: twitter.com
  // GitHubAuthProviderID: github.com
  // AppleAuthProviderID: apple.com
  // YahooAuthProviderID: yahoo.com
  // MicrosoftAuthProviderID: hotmail.com
}
