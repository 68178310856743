import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentHeaderService {
  private pageNameCurrent = new BehaviorSubject<string>('home');
  private pageNameCurrent$ = this.pageNameCurrent.asObservable();
  private pageTitleExtra = new BehaviorSubject<string>('extra');
  private pageTitleExtra$ = this.pageTitleExtra.asObservable();

  private phrasesInHtml = [
    "Almost there!",
    "Calling Flickr...",
    "Don't blame me.",
    "We're getting there.",
    "Are we there yet?",
    "Go. Network GO!",
    "Biting bytes",
    "Waiting for the paint to dry.",
    "(Yawn)",
    "tap. tap. tap."
  ];

  constructor() {}

  getPageNameCurrent(): Observable<string> {
    return this.pageNameCurrent$;
  }
  setPageNameCurrent(pageName: string) {
    this.pageNameCurrent.next(pageName);
  }
  getPageTitleExtra(): Observable<string> {
    return this.pageTitleExtra$;
  }
  setPageTitleExtra(pageTitleExtra: string) {
    this.pageTitleExtra.next(pageTitleExtra);
  }

  getRamdomPhrase(): Observable<string>{
    const ramdomNum = Math.floor(Math.random() * this.phrasesInHtml.length);
    return of(this.phrasesInHtml[ramdomNum]);
  }
}
