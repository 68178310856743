import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const ROUTES: Routes = [
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'music',
    loadChildren: () => import('./music/music.module').then(m => m.MusicModule)
  },
  {
    path: 'photography',
    loadChildren: () =>
      import('./photography/photography.module').then(m => m.PhotographyModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'user/yourstuff',
    loadChildren: () =>
      import('./user/your-stuff/your-stuff.module').then(m => m.YourStuffModule)
  },
  {
    path: 'work',
    loadChildren: () => import('./work/work.module').then(m => m.WorkModule)
  },
  {
    path: 'pagenotfound',
    loadChildren: () =>
      import('./page-not-found/page-not-found.module').then(
        m => m.PageNotFoundModule
      )
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
  },
  {
    path: 'expr',
    loadChildren: () => import('./expr/expr.module').then(m => m.ExprModule)
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'pagenotfound', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      ROUTES,
      // { enableTracing: true } // <-- debugging purposes only
      // { enableTracing: true } // <-- debugging purposes only
{ scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
